import gql from 'graphql-tag';

const UPDATE_PROJECT_MUTATION = gql`
  mutation UPDATE_PROJECT_MUTATION(
    $id: ID!
    $name: String
    $max_message_length: Int
    $max_channel_count: Int
    $profanity_deny_type: String
    $profanity_filter: String
    $papago: PapagoInput
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        max_message_length: $max_message_length
        max_channel_count: $max_channel_count
        profanity_deny_type: $profanity_deny_type
        profanity_filter: $profanity_filter
        papago: $papago
      }
    ) {
      project {
        id
      }
    }
  }
`;

const CREATE_PROJECT_FROM_MAIL_MUTATION = gql`
  mutation createProjectFromMail(
    $ncpid: String!
    $tempkey: String!
    $name: String
  ) {
    createProjectFromMail(
      input: { ncpid: $ncpid, tempkey: $tempkey, name: $name }
    ) {
      project {
        id
        name
      }
    }
  }
`;

const PAID_PROJECT_MUTATION = gql`
  mutation paidProject($id: ID!) {
    paidProject(input: { id: $id }) {
      project {
        paid
      }
    }
  }
`;

export {
  UPDATE_PROJECT_MUTATION,
  CREATE_PROJECT_FROM_MAIL_MUTATION,
  PAID_PROJECT_MUTATION,
};
