import {
  DashboardOutlined,
  UserOutlined,
  SearchOutlined,
  WechatOutlined,
  SettingOutlined,
  ReadOutlined,
  CreditCardOutlined,
  BookOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { guideConfig } from '../config';
import Utils from '../utils';

const dashBoardNavTree = [
  {
    key: 'gamechat',
    title: 'sidenav',
    submenu: [
      {
        key: 'dashboards',
        path: '/:projectId/dashboards',
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'users',
        title: 'sidenav.member',
        path: '/:projectId/user',
        icon: UserOutlined,
        submenu: [
          {
            key: 'users-list',
            path: '/:projectId/user/list',
            title: 'sidenav.member.list',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'users-block',
            path: '/:projectId/user/block',
            title: 'sidenav.member.block',
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'chats',
    path: '/:projectId/chat',
    title: 'sidenav.chat',
    icon: WechatOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'searchs',
    path: '/:projectId/search',
    title: 'sidenav.search',
    icon: SearchOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'settings',
    path: '/:projectId/settings',
    title: 'sidenav.settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [],
  },
  // {
  //   key: 'billing',
  //   path: '/:projectId/billing',
  //   title: 'sidenav.billing',
  //   icon: CreditCardOutlined,
  //   breadcrumb: true,
  //   submenu: [],
  // },
  {
    key: 'jobmanagement',
    path: '/:projectId/jobmanagement',
    title: 'sidenav.jobmanagement',
    icon: DownloadOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

const docsNavTree = [
  {
    key: 'docs',
    path: '/app/docs',
    title: 'sidenav.docs',
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'unity',
        url: true,
        title: 'sidenav.docs.unity',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'javascript',
        url: true,
        title: 'sidenav.docs.javascript',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree, ...docsNavTree];

export default navigationConfig;
