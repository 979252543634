const version = '1.2.2';

const isBeta = process.env.REACT_APP_BETA;

const guideConfig = {
  dashboard: {
    pay: `https://www.ncloud.com/charge/region`,
  },
  papago: {
    guide: `https://www.ncloud.com/product/aiService/papagoTranslation`,
  },
  unity: {
    ko: 'https://guide.ncloud-docs.com/docs/ko/gamechat-gamechatunity',
    en: 'https://guide.ncloud-docs.com/docs/en/gamechat-gamechatunity',
  },
  javascript: {
    ko: 'https://guide.ncloud-docs.com/docs/ko/gamechat-javascript-sdk',
    en: 'https://guide.ncloud-docs.com/docs/en/gamechat-javascript-sdk',
  },
};

const changeLog = {
  unity:
    'https://raw.githubusercontent.com/itsbio/gamechat-docs/master/download/unity/Update.md',

  unity_global:
    'https://raw.githubusercontent.com/itsbio/gamechat-docs/master/download/unity/Update-global.md',
};

if (isBeta === 'true') {
  //Guide
  guideConfig.dashboard.pay = `https://beta-www.ncloud.com/charge/region`;
  guideConfig.papago.guide = `https://beta-www.ncloud.com/product/aiService/papagoTranslation`;
  guideConfig.unity.ko =
    'https://guide.ncloud-docs.com/beta/docs/ko/gamechat-gamechatunity';
  guideConfig.unity.en =
    'https://guide.ncloud-docs.com/beta/docs/en/gamechat-gamechatunity';
  guideConfig.javascript.ko =
    'https://guide.ncloud-docs.com/beta/docs/ko/gamechat-javascript-sdk';
  guideConfig.javascript.en =
    'https://guide.ncloud-docs.com/beta/docs/en/gamechat-javascript-sdk';

  //ChangeLog
  changeLog.unity =
    'https://raw.githubusercontent.com/itsbio/gamechat-docs/master/download/unity/beta-Update.md';
  changeLog.unity_global =
    'https://raw.githubusercontent.com/itsbio/gamechat-docs/master/download/unity/beta-Update-global.md';
}

export { version, guideConfig, changeLog };
