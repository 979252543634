import React, { createContext, useState } from 'react';

const GlobalContext = createContext({
  contextState: {
    project: {},
    profile: {},
  },
  contextActions: {
    setProject: () => {},
    setProfile: () => {},
  },
});

const GlobalProvider = ({ children }) => {
  const [project, setProject] = useState({});
  const [profile, setProfile] = useState({});

  const value = {
    contextState: { project, profile },
    contextActions: { setProject, setProfile },
  };

  return (
    <GlobalContext.Provider value={[value.contextState, value.contextActions]}>
      {children}
    </GlobalContext.Provider>
  );
};

const GlobalConsumer = GlobalContext.Consumer;

export { GlobalProvider, GlobalConsumer };

export default GlobalContext;
