import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthViews from 'views/auth-views';
import IEModal from 'components/layout-components/IEModal';

export const AuthLayout = () => {
  window.sessionStorage.removeItem('gamechat-alert');

  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={AuthViews} />
      </Switch>
      <IEModal />
    </div>
  );
};

export default AuthLayout;
