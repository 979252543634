import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import Utils from 'utils';
export const APP_NAME = 'Game Chat';
export const API_BASE_URL = '';
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: Utils.getCurrentLocale() || 'en-US',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false,
};
