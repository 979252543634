import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BlankViews from 'views/blank-views';

export const BlankLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={BlankViews} />
      </Switch>
    </div>
  );
};

export default BlankLayout;
