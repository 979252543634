import React, { useContext } from 'react';
import { Layout, Grid } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import FooterPay from 'components/layout-components/FooterPay';
import IEModal from 'components/layout-components/IEModal';
import AppViews from 'views/app-views';
import navigationConfig from 'configs/NavigationConfig';
import validate from 'uuid-validate';
import { signOut } from 'redux/actions/Auth';
import { useParams } from 'react-router';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import { useIntl } from 'react-intl';
import GlobalContext from '../../context/GlobalContext';
import utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, signOut }) => {
  const params = useParams();
  const { formatMessage } = useIntl();
  const [contextState] = useContext(GlobalContext);

  const projectId = params.projectid;
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname.replace(projectId, ':projectId')
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const showOtherProjectLogin = () => {
    if (
      window.sessionStorage.getItem('gamechat-alert') === 'false' ||
      window.sessionStorage.getItem('gamechat-alert') == null
    ) {
      window.sessionStorage.setItem('gamechat-alert', 'true');
      window.alert(formatMessage({ id: 'auth.otherprojectlogin' }));
      signOut(projectId);
      localStorage.setItem('company_id', projectId);
      window.location.assign('/');
    } else {
      signOut(projectId);
      localStorage.setItem('company_id', projectId);
      window.location.assign('/');
    }
  };

  // Project ID가 ncpid가 아니면
  if (validate(projectId, 4) === false) {
    // 주소에 입력된 ncpID와 보유한 ncpID가 다른가?
    const companyId = projectId;
    const localCompanyId = localStorage.getItem('company_id');

    if (
      utils.isEmpty(companyId) === false &&
      utils.isEmpty(localCompanyId) === false
    ) {
      if (companyId === localCompanyId) {
        window.location.href = './';
      } else {
        showOtherProjectLogin();
      }
    } else {
      showOtherProjectLogin();
    }
  } else {
    return (
      <Layout>
        <HeaderNav isMobile={isMobile} />
        {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-container">
          {isNavSide && !isMobile ? (
            <SideNav routeInfo={currentRouteInfo} />
          ) : null}
          <Layout
            className="app-layout"
            style={{ paddingLeft: getLayoutGutter() }}
          >
            <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
              />
              <Content>
                <Switch>
                  <Route path="" component={AppViews} />
                </Switch>
              </Content>
            </div>
            <IEModal />
            {contextState?.project?.paid !== 2 ? null : (
              <FooterPay isMobile={isMobile} />
            )}
            {/* <Footer /> */}
          </Layout>
        </Layout>
        {isMobile && <MobileNav />}
      </Layout>
    );
  }
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default withRouter(connect(mapStateToProps, { signOut })(AppLayout));
