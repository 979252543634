import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Layout, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ProjectPayModal from './ProjectPayModal';
import { guideConfig } from 'config';

const { Footer } = Layout;

const FooterPay = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [height, setHeight] = useState(100);

  const ref = useRef(null);

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [updateHeight]);

  const updateHeight = useCallback(() => {
    if (ref && ref.current) {
      const offsetHeight = ref.current.offsetHeight;
      setHeight(offsetHeight + 20);
    }
  }, [ref]);

  return (
    <div>
      <div style={{ height: height }} />
      <Footer
        style={{
          backgroundColor: '#1e232e',
          padding: '8px 50px',
          bottom: 0,
          position: 'fixed',
          textAlign: 'center',
          zIndex: 1049,
          width: props.isMobile
            ? `calc(100%)`
            : `calc(100% - ${props.collapsed ? '80px' : '250px'})`,
        }}
      >
        <div ref={ref}>
          <ExclamationCircleOutlined
            style={{
              color: '#fff',
              fontSize: '19px',
              verticalAlign: 'middle',
              marginRight: '7px',
              lineHeight: '20px',
            }}
          />
          <span style={{ color: '#fff' }}>
            <FormattedMessage id="footerpay.desc1" />
            <span style={{ color: 'yellow', fontWeight: 'bold' }}>
              <FormattedMessage id="footerpay.desc2" />
            </span>
            <FormattedMessage id="footerpay.desc3" />
            <a
              style={{ marginLeft: '5px' }}
              target="_blank"
              href={`${guideConfig.dashboard.pay}?language=${localStorage.locale || 'en-US'
                }`}
            >
              <FormattedMessage id="footerpay.desc4" />
            </a>
          </span>
          <Button
            style={{
              marginLeft: '30px',
              backgroundColor: '#ff4d4f',
              borderColor: '#ff4d4f',
              color: '#fff',
            }}
            type="danger"
            size="small"
            disabled
            onClick={() => setModalVisible(true)}
          >
            <FormattedMessage id="footerpay.button" />
          </Button>
          <ProjectPayModal
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
            }}
          />
        </div>
      </Footer>
    </div>
  );
};

export default FooterPay;
