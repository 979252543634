import React from 'react';
import { Switch, Route } from 'react-router-dom';
import VerifyViews from 'views/verify-views';

export const VeriyfyLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={VerifyViews} />
      </Switch>
    </div>
  );
};

export default VeriyfyLayout;
