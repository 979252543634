import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  CREATE_FROM_MAIL,
  CHANGE_ACCOUNT,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  RESET_PASSWORD,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token, companyID) => {
  return {
    type: AUTHENTICATED,
    token,
    companyID,
  };
};

export const signOut = (redirect) => {
  return {
    type: SIGNOUT,
    redirect,
  };
};

export const signOutSuccess = (redirect) => {
  return {
    type: SIGNOUT_SUCCESS,
    redirect,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (companyId) => {
  return {
    type: SIGNUP_SUCCESS,
    companyId,
  };
};

export const createFromMail = (user) => {
  return {
    type: CREATE_FROM_MAIL,
    payload: user,
  };
};

export const resetPassword = (user) => {
  return {
    type: RESET_PASSWORD,
    payload: user,
  };
};

export const changeAccount = (user) => {
  return {
    type: CHANGE_ACCOUNT,
    payload: user,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
