import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`/profile`}
          component={lazy(() => import(`../app-views/profile`))}
        />
       <Route path={``} component={lazy(() => import(`../app-views/apps/`))} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
