import React, { useContext } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import {
  EditOutlined,
  ProjectOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import GlobalContext from '../../context/GlobalContext';

export const NavProfile = ({ signOut }) => {
  const params = useParams();
  const [contextState] = useContext(GlobalContext);

  const company_id = localStorage.getItem('company_id');
  const profileImg = contextState?.profile?.profile_url;
  const menuItem = [
    {
      title: <FormattedMessage id="header.profile" />,
      icon: EditOutlined,
      path: params?.projectid ? `/${params?.projectid}/profile` : `/profile`,
      show: true,
    },
    {
      title: <FormattedMessage id="header.project" />,
      icon: ProjectOutlined,
      path: '/',
      show: process.env.REACT_APP_NAVERCLOUD === 'true' ? false : true,
    },
    
  ];
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            style={{ minWidth: '40px' }}
            src={profileImg}
            icon={<UserOutlined />}
          />
          <div className="pl-3">
            <h4 className="mb-0 text-truncate" style={{ width: '8.5rem' }}>
              {contextState?.profile?.local?.name}
            </h4>
            <p
              className=" mb-0 text-muted text-truncate"
              style={{ width: '8rem' }}
            >
              {contextState?.profile?.local?.email}
            </p>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            if (el.show === true) {
              return (
                <Menu.Item key={i}>
                  <a href={el.path}>
                    <Icon className="mr-3" type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </a>
                </Menu.Item>
              );
            }
            return;
          })}
          <Menu.Item
            key={menuItem.legth + 1}
            onClick={(e) => {
              signOut(company_id);
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                <FormattedMessage id="header.signout" />
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
