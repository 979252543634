import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const BlankViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`/monitor/l7check`}
          component={lazy(() => import(`./l7check`))}
        />
        <Route
          path={`${match.url}/error-1`}
          component={lazy(() => import(`../errors/error-page-1`))}
        />
        <Route
          path={`${match.url}/error-2`}
          component={lazy(() => import(`../errors/error-page-2`))}
        />
        <Route
          path={``}
          component={lazy(() => import(`../errors/error-page-1`))}
        />
      </Switch>
    </Suspense>
  );
};

export default BlankViews;
