import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import NavLanguage from 'components/layout-components/NavLanguage';
import Loading from 'components/shared-components/Loading';

export const VerifyViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <div style={{ position: 'absolute', left: '100%', marginLeft: -60 }}>
        <NavLanguage dark />
      </div>
      <Switch>
        <Route
          path={`/:companyId/verify/project`}
          component={lazy(() => import(`./authentication/ProjectFromMail`))}
        />
        <Route
          path={`/:companyId/verify/account`}
          component={lazy(() => import(`./authentication/AccountFromMail`))}
        />
        <Route
          path={`/:companyId/verify/initPassword`}
          component={lazy(() => import(`./authentication/InitPassword`))}
        />
        <Route
          path={`/:companyId/verify/confirmAccount`}
          component={lazy(() => import(`./authentication/ChangeAccount`))}
        />
        <Route
          path={``}
          component={lazy(() => import(`../errors/error-page-1`))}
        />
      </Switch>
    </Suspense>
  );
};

export default VerifyViews;
