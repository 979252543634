import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import Loading from 'components/shared-components/Loading';
import ChatService from '../../services/ChatService';
import GlobalContext from '../../context/GlobalContext';

import { PROJECT_QUERY } from '../../graphql/query/project';

export const AppViews = ({ match }) => {
  const projectId = match.params.projectid;
  const [contextState, contextActions] = useContext(GlobalContext);

  const projectData = useQuery(PROJECT_QUERY, {
    variables: { id: projectId },
    skip: !projectId,
  });

  useEffect(() => {
    if (
      projectData?.data?.project &&
      projectData.data.project !== contextState?.project
    ) {
      contextActions.setProject(projectData.data.project);
    }
  }, [projectData]);

  useEffect(() => {
    ChatService.getInstance().setProjectId(projectId);
    ChatService.getInstance().bind('onConnected', function (data) {
      console.log('[onConnected]');
      // console.log('data = ', data);
    });
    ChatService.getInstance().bind(
      'onErrorReceived',
      function (channel, message) {
        console.log('[onErrorReceived]');
        console.log('channel = ', channel);
        console.log('message = ', message);
      }
    );
    ChatService.getInstance().bind(
      'onDisconnected',
      function (channel, message) {
        console.log('[onDisconnected]');
        console.log('channel = ', channel);
        console.log('message = ', message);
      }
    );
  }, []);

  const Chat = lazy(() => import('./chat'));
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Redirect from={`/:projectid`} to={`/:projectid/dashboards`} exact />
        <Route
          path={`/:projectid/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`/:projectid/user/list`}
          component={lazy(() => import(`./user/list`))}
        />
        <Route
          path={`/:projectid/user/block`}
          component={lazy(() => import(`./user/block`))}
        />
        <Route
          path={`/:projectid/chat`}
          render={(props) => <Chat {...props} />}
        />
        <Route
          path={`/:projectid/search`}
          component={lazy(() => import(`./search`))}
        />
        <Route
          path={`/:projectid/settings`}
          component={lazy(() => import(`./settings`))}
        />
        <Route
          path={`/:projectid/profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
          path={`/:projectid/billing`}
          component={lazy(() => import(`./billing`))}
        />
        <Route
          path={`/:projectid/jobmanagement`}
          component={lazy(() => import(`./jobmanagement`))}
        />
        <Redirect from={`${match.url}`} to={`/`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
