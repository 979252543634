import fetch from 'auth/FetchInterceptor';
import Utils from '../utils';
const JwtAuthService = {};

JwtAuthService.signIn = function (username, password, ncpid) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/signin`,
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
      'accept-language': Utils.getCurrentLocale(),
    },
    data: {
      username,
      password,
      ncpid,
    },
  });
};

JwtAuthService.signUp = function (
  email,
  password,
  confirm,
  name,
  ncp_project_id,
  timezone,
  key
) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/signup`,
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
      'accept-language': Utils.getCurrentLocale(),
    },
    data: {
      email,
      password,
      confirm,
      name,
      ncp_project_id,
      timezone,
      key,
    },
  });
};

JwtAuthService.signOut = function () {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/signout`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept-language': Utils.getCurrentLocale(),
    },
    data: {},
  });
};

JwtAuthService.resetPassword = function (email, ncp_project_id, key, password) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/password_reset`,
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
      'accept-language': Utils.getCurrentLocale(),
    },
    data: {
      email,
      ncp_project_id,
      key,
      password,
    },
  });
};

JwtAuthService.changeAccount = function (
  ncp_project_id,
  key,
  preaccount,
  email,
  name,
  password
) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/changeadmin`,
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
      'accept-language': Utils.getCurrentLocale(),
    },
    data: {
      ncp_project_id,
      key,
      preaccount,
      email,
      name,
      password,
    },
  });
};

JwtAuthService.getCompanyID = function (companyID) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/companys/${companyID}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',

      'accept-language': Utils.getCurrentLocale(),
    },
    data: {},
  });
};
export default JwtAuthService;
