import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import ReactGA from 'react-ga';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import HeaderLayout from 'layouts/header-layout';
import VerifyLayout from 'layouts/verify-layout';
import BlankLayout from 'layouts/blank-layout';
import GlobalContext from '../context/GlobalContext';
import { PROFILE_QUERY } from '../graphql/query/profile';
import Utils from '../utils';

export const Views = (props) => {
  if (process.env.REACT_APP_GA) {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const { locale, redirect, token } = props;
  // console.log(props);
  const [contextState, contextActions] = useContext(GlobalContext);
  const storagetoken = localStorage.getItem(AUTH_TOKEN);
  const currentAppLocale = Utils.getAppLocaleData(locale);

  // const isAuthenticated = storagetoken && token ? true : false;
  const [isAuthenticated, setAuthenticated] = useState(undefined);

  // Set Context
  const profileData = useQuery(PROFILE_QUERY, {
    //skip: !isAuthenticated,
    fetchPolicy: isAuthenticated ? 'network-only' : 'cache-only',
  });

  useEffect(() => {
    if (storagetoken && token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [storagetoken, token]);

  useEffect(() => {
    if (isAuthenticated && profileData.data && profileData.data.me) {
      const dataSource = profileData.data.me;

      if (dataSource.id !== contextState.profile.id) {
        contextActions.setProfile(dataSource);
        Utils.setDefaultTimezone(dataSource.timezone);
      }
    }
  }, [profileData]);

  const RouteInterceptor = ({ path, children, isAuthenticated, ...rest }) => {
    if (isAuthenticated == null) {
      return <div></div>;
    }
    if (path === '/auth') {
      return (
        <Route
          path={path}
          render={({ location }) => {
            if (isAuthenticated) {
              return (
                <Redirect
                  to={{
                    pathname: '/',
                  }}
                />
              );
            } else {
              return children;
            }
          }}
        />
      );
    } else {
      return (
        <Route
          path={path}
          render={({ location }) => {
            if (isAuthenticated) {
              return children;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/auth/login',
                    state: { from: location, redirect: redirect },
                  }}
                />
              );
            }
          }}
        />
      );
    }
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route path="/auth">
            {RouteInterceptor({
              path: '/auth',
              children: <AuthLayout />,
              isAuthenticated,
            })}
          </Route>
          <Route path="/monitor/l7check" exact>
            <BlankLayout />
          </Route>
          <Route path="/error">
            <BlankLayout />
          </Route>
          <Route path="/:companyId/verify">
            <VerifyLayout />
          </Route>
          <Route path="/profile">
            {RouteInterceptor({
              path: '/profile',
              children: <HeaderLayout />,
              isAuthenticated,
            })}
          </Route>
          <Route path="/:projectid">
            {RouteInterceptor({
              path: '/:projectid',
              children: <AppLayout props={props} />,
              isAuthenticated,
            })}
          </Route>
          <Route path="/">
            {RouteInterceptor({
              path: '/',
              children: <HeaderLayout />,
              isAuthenticated,
            })}
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token, redirect } = auth;
  return { locale, token, redirect };
};

export default withRouter(connect(mapStateToProps)(Views));
