import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import NavLanguage from 'components/layout-components/NavLanguage';
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <div
        style={{
          position: 'absolute',
          left: '100%',
          marginLeft: -60,
          zIndex: 1,
        }}>
        <NavLanguage dark />
      </div>
      <Switch>
        <Route
          path={`${match.url}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${match.url}/login-1`}
          component={lazy(() => import(`./authentication/login-1`))}
        />
        <Route
          path={`${match.url}/company`}
          component={lazy(() => import(`./authentication/company`))}
        />
        <Route
          path={`${match.url}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        {/* <Route
          path={`${match.url}/register`}
          component={lazy(() => import(`./authentication/register-2`))}
        /> */}
        <Route
          path={`${match.url}/notfound`}
          component={lazy(() => import(`../errors/findProject`))}
        />
        <Route
          path={``}
          component={lazy(() => import(`../errors/error-page-1`))}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
