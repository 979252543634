import { gql } from '@apollo/client';

const PROJECTS_QUERY = gql`
  query PROJECTS_QUERY(
    $ncpId: String!
    $offset: Int
    $per_page: Int
    $query: String
    $search: String
  ) {
    projects(
      ncpId: $ncpId
      offset: $offset
      per_page: $per_page
      query: $query
      search: $search
    ) {
      totalCount
      edges {
        node {
          id
          name
          ncp_project_id
          message_retention_hours
          max_message_length
          profanity_deny_type
          profanity_filter
          created_at
          updated_at
        }
      }
    }
  }
`;

const PROJECT_QUERY = gql`
  query PROJECT_QUERY($id: ID!) {
    project(id: $id) {
      id
      name
      ncp_project_id
      paid
      message_retention_hours
      max_message_length
      max_channel_count
      profanity_deny_type
      profanity_filter
      created_at
      updated_at
      papago {
        use
        client_id
        client_secret
      }
    }
  }
`;

const PROJECT_VOIDED_QUERY = gql`
  query PROJECT_VOIDED_QUERY($id: ID!) {
    project(id: $id) {
      id
      voided_enable
      voided_count
      voided_price
      voided_message
      voided_messageMulti {
        default
        lang
        value
      }
      voided_updatedAt
      google_key {
        google
        googleplay
      }
      language_list
    }
  }
`;

const PROJECT_CS_PUSH_QUERY = gql`
  query PROJECT_CS_PUSH_QUERY($id: ID!) {
    project(id: $id) {
      id
      cs_push_enable
      cs_push_msg {
        lang
        value
        default
      }
      language_list
      outbound_send_mail_address
      outbound_send_mail_name
      outbound_send_mail_header
      outbound_send_mail_footer
      cs_mail_enable
    }
  }
`;

const PROJECT_LANGUAGE_QUERY = gql`
  query PROJECT_LANGUAGE_QUERY($id: ID!) {
    project(id: $id) {
      language_list
    }
  }
`;

const PROJECT_OBJECT_STORAGE_QUERY = gql`
  query PROJECT_OBJECT_STORAGE_QUERY($id: ID!) {
    project(id: $id) {
      id
      ncp {
        objectstorage_access_key
        objectstorage_secret_key
        objectstorage_bucket
      }
    }
  }
`;

const PROJECT_ADVERTISE_QUERY = gql`
  query PROJECT_ADVERTISE_QUERY($id: ID!) {
    project(id: $id) {
      id
      advertise {
        agree
        key
        senderMailAddress
        senderMailName
        targetMailAddress
      }
    }
  }
`;

export {
  PROJECTS_QUERY,
  PROJECT_QUERY,
  PROJECT_VOIDED_QUERY,
  PROJECT_CS_PUSH_QUERY,
  PROJECT_LANGUAGE_QUERY,
  PROJECT_OBJECT_STORAGE_QUERY,
  PROJECT_ADVERTISE_QUERY,
};
