import * as GameChat from 'gamechat-client-js';

let instance = null;
let isConnecting = false;
class ChatService {
  constructor() {
    if (instance) {
      return instance;
    }
    console.log('ChatService initialize');
    this.gc = new GameChat.Chat();
    this.gc.initialize('');
    // Alpha
    this.gc.setServerUrl(`${process.env.REACT_APP_API_ENDPOINT_URL}`);
    this.gc.setSocketUrl(`${process.env.REACT_APP_SOCKET_ENDPOINT_URL}`);
    instance = this;
  }
  static getInstance() {
    if(instance) return instance;
    return new ChatService();
  }
  setProjectId(projectId) {
    if (this.gc) this.gc.setProjectId(projectId);
  }
  bind(name, func) {
    this.gc.bind(name, func);
  }
  connect(userId, nickname, profile, callback) {
    
    if(isConnecting) return;
    isConnecting = true;
    console.log('ChatService Connecting...');
    
    this.gc.setUser({
      id: userId,
      name: nickname,
      profile: profile,
    });
    if (this.gc.isConnected()) {
      callback(null, userId);
    }
    this.gc.connect(userId, function (err, res) {
      callback(err, res);
      isConnecting = false;
    });
  }
  joinChannel(channel_id) {
    return new Promise((resolve, reject) => {
      this.gc.subscribe(channel_id);
    });
    // this.actions.getMessages(channel.id, 0, 1);
    // this.state.messages[channel.id] &&
    //   this.actions.setCursor(
    //     channel.id,
    //     Object.keys(this.state.messages[channel.id]).pop()
    //   )
  }
  getChannels(offset, limit, search, query, callback) {
    // this.gc.getChannelsSearch(
    //   offset,
    //   limit,
    //   search,
    //   query,
    //   (err, channelData) => {
    //     callback(err, channelData);
    //   }
    // );
    this.gc.getChannels(offset, limit, (err, channelData) => {
      callback(err, channelData);
    });
  }
  async getMessages(opt, callback) {
    const messages = await this.gc.getMessages(opt, callback);
    callback(null, messages);
  }
  async getSubscriptions(channel_id, offset, limit, callback) {
    const messages = await this.gc.getSubscriptions(
      channel_id,
      offset,
      limit,
      callback
    );
    callback(null, messages);
  }
  disconnect() {
    return new Promise((resolve, reject) => {
      this.gc.disconnect();
      isConnecting = false;
    });
  }
  sendMessage(channel_id, message) {
    return new Promise((resolve, reject) => {
      this.gc.sendMessage(channel_id, message);
    });
  }
  translateMessage(source, target, message, channelId = '') {
    return new Promise((resolve, reject) => {
      return this.gc.translateMessage(source, target, message, channelId);
    });
  }

  // createChannel(name, callback) {
  //   return new Promise((resolve, reject) => {
  //     this.gc.createChannel(name, function (channel, error) {
  //       callback(channel, error);
  //     });
  //   });
  // }
}

export default ChatService;
