import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN, COMPANY_ID } from 'redux/constants/Auth';
import { GlobalProvider } from './context/GlobalContext';
import JwtAuthService from 'services/JwtAuthService';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloProvider } from 'react-apollo';
import Utils from './utils';
import { message as antdMessage } from 'antd';
let apolloClient = null;

antdMessage.config({
  maxCount: 1,
  duration: 2,
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  const companyid = localStorage.getItem(COMPANY_ID);
  const locale = Utils.getCurrentLocale();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
      ncpid: companyid,
      'accept-language': locale,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // console.log(graphQLErrors);
    graphQLErrors.map(({ message, state, locations, path, code }) => {
      if (state !== undefined && state !== null) {
        for (const key in state) {
          antdMessage.error({
            content: state[key],
          });
        }
      } else {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
        );
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    console.error(`[Network error]: ${networkError?.result?.message}`);

    if (networkError.statusCode === 401 || networkError.statusCode === 403) {
      localStorage.removeItem(AUTH_TOKEN);
      JwtAuthService.signOut().then((result) => {
        if (result && result.status === 200) {
          window.location.assign('/');
        } else {
          window.location.assign('/');
        }
      });
    } else {
      if (networkError.result && networkError.result.message) {
        let errorMessage = networkError.result.message;

        antdMessage.error(`${errorMessage} (${networkError.statusCode})`);
      }
    }
  }
});

if (apolloClient == null) {
  apolloClient = new ApolloClient({
    uri: `${process.env.REACT_APP_API_ENDPOINT_URL}/graphql`,
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
  });
}
function App() {
  return (
    <div className="App">
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <GlobalProvider>
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </GlobalProvider>
        </Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;
